import React from 'react'
import Divider from 'components/divider'
class Advisers extends React.Component {
  render() {
    const data = this.props.data

    return (
      <div>
        {data.map(function(contact, key) {
          return (
            <div className="c-contact" key={key}>
            <div className="o-grid">
                <div className="o-grid__item">
                  <h2 className="article-title mb-20">
                    {contact.name}
                  </h2>
                  <Divider color="night" />
                </div>
                <div className="o-grid__item">
                  <div className="c-contact__address">
                    {
                      contact.address.map(function(line, key) {
                         return (
                           <div
                            key={key}
                            className="c-contact__address-line"
                          >
                            {line}
                          </div>
                         )
                      })
                    }
                  </div>
                </div>
                <div className="o-grid__item">
                  <div className="c-contact__address">
                    <span className="u-bold">Website</span>
                    <br />
                    <a className="u-color-steel" href={contact.website}>
                      {contact.website}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Advisers