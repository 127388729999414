import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import Header from 'components/header'
// import CTA from 'components/call-to-action'
import Advisers from './_advisers'
import data from './advisers.json'


export default () => (
  <Layout>
    <main>
      <Helmet>
        <title>Advisers | Helios Underwriting</title>
      </Helmet>

      <Header />

      {/* Article fades in the content */}
      <article className="o-content">
        <section>
          <div className="lg:u-flex">
            <h1 className="h1 lg:u-2/3 padding-md">
              Advisers
            </h1>
            <div className="lg:u-1/3 u-color-emperor padding-md">


              {/* If not using this section, only delete h2 and p */}

              {/* Do not delete wrapping div */}

            </div>
          </div>
        </section>

        <section>
          <Advisers data={data} />
        </section>

      </article>

    </main>
  </Layout>
)
